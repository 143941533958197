export const initalPayOnEntryState = { 
    offerID: undefined, 
    showForm: false 
  };

export default function poeOfferReducer (state, { type, payload }) {
    switch (type) {
      case "ADD_POE_OFFER":
        return { ...state, offerID: undefined, showForm: true };
      case "EDIT_POE_OFFER":
        return { ...state, offerID: payload.offerID, showForm: true };
      case "CLOSE_FORM":
        return { ...state, offerID: undefined, showForm: false };
      default:
        return state;
    }
  }