import React, { useState, useReducer } from "react";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  darken,
  lighten,
} from "@material-ui/core/styles";
import { grey, pink, red } from "@material-ui/core/colors";
import LayoutReducer from "../reducers/layout/layoutReducer";

export const ThemeSwitchContext = React.createContext();

const ThemeProvider = (props) => {
  const [isLight, setIsLight] = useState(true);
  const layoutReducer = useReducer(LayoutReducer, { rootWidth: "lg" });

  const toggleTheme = () => {
    setIsLight(!isLight);
  };

  const theme = createMuiTheme({
    nprogress: {
      color: isLight ? "#000" : "#fff",
    },
    palette: {
      //menu : {
      //  main:   isLight   ? '#353f50' : '#333',
      //  light:  isLight   ? '#353f50' : '#333',
      //  dark:   isLight   ? '#353f50'  : '#333'
      //},
      primary: {
        main: isLight ? "#006ba6" : lighten("#006ba6", 0.2),
        light: isLight ? lighten("#006ba6", 0.1) : lighten("#006ba6", 0.2),
        dark: isLight ? darken("#006ba6", 0.1) : lighten("#006ba6", 0.2),
      },
      secondary: {
        main: isLight ? darken(red.A400, 0.2) : lighten(red.A400, 0.2),
        light: isLight ? lighten(red.A400, 0.1) : lighten(red.A400, 0.2),
        dark: isLight ? darken(red.A400, 0.3) : red.A400,
        //main: isLight ? darken(pink.A400, 0.1) : pink[200],
      },
      background: {
        default: isLight ? "#fff" : "#121212",
        paper: isLight ? "#fff" : "#333",
      },
      grey: {
        main: isLight ? "#646a75" : darken("#646a75", 0.3),
      },
      //header: {
      //  main: isLight ? darken("#f2f7fa",.1) : darken("#646a75", 0.3),
      //},
      white: {
        main: "#fff",
      },
      error: {
        main: isLight ? "#e70022" : "#e70022",
        light: isLight ? lighten("#e70022", 0.1) : lighten("#e70022", 0.2),
        dark: isLight ? darken("#e70022", 0.1) : lighten("#e70022", 0.2),
      },
      type: isLight ? "light" : "dark",
      selectedItem: {
        main: "rgba(25, 118, 210, 0.08)",
      },
    },
  });

  //custom palette names

  theme.palette.highlight = {
    main: isLight ? "#fffef5" : darken("#fff9c2", 0.7),
    light: isLight ? lighten("#fffeed", 0.2) : darken("#fff9c2", 0.6),
    dark: isLight ? darken("#fcfae1", 0.1) : darken("#fff9c2", 0.8),
  };
  theme.palette.amano = {
    main: isLight ? "#77a5c7" : darken("#77a5c7", 0.3),
  };
  theme.palette.amano.closed = {
    main: isLight ? "#66593E" : lighten("#66593E", 0.4),
  };
  theme.palette.amano.active = {
    light: isLight ? "#a4c99d" : "#a4c99d",
    main: isLight ? "#a4c99d" : "#a4c99d",
    dark: isLight ? darken("#a4c99d", 0.3) : darken("#a4c99d", 0.4),
  };
  theme.palette.amano.warnings = {
    good: isLight ? "#a4c99d" : "#a4c99d",
    low: isLight ? "#F3AB2C" : "#F3AB2C",
    warning: isLight ? "#F3AB2C" : "#F3AB2C",
    //high:
    //critical:
    fire: isLight ? "#FE626B" : "#FE626B",
  };

  theme.palette.amano.base = {
    main: "rgb(0, 107, 166)"
  };

  theme.palette.amano.base.primary = {
    main:   isLight ? "rgb(0, 107, 166)"              : darken("rgb(0, 107, 166)", 0.2),
    light:  isLight ? lighten("rgb(0, 107, 166)",0.2) : "rgb(0, 107, 166)",
    dark:   isLight ? darken("rgb(0, 107, 166)",0.3)  : darken("rgb(0, 107, 166)",0.3)
  };
  theme.palette.amano.base.secondary = {
    main:   isLight ? "rgb(243,171,44)"              : darken("rgb(243,171,44)", 0.2),
    light:  isLight ? lighten("rgb(243,171,44)",0.2) :darken("rgb(243,171,44)", 0.1),
    dark:   isLight ? darken("rgb(243,171,44)",0.3)  : darken("rgb(243,171,44)",0.4)
  };

  theme.palette.menu = isLight ? "#353f50" : "#333";
  theme.palette.header = isLight
    ? darken("#f2f7fa", 0.1)
    : darken("#646a75", 0.3);

  theme.palette.background.level2 = isLight ? theme.palette.grey[100] : "#333";

  theme.palette.background.level1 = isLight
    ? "#f2f7fa"
    : theme.palette.grey[900];

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeSwitchContext.Provider
        value={{ isLight, toggleTheme, layoutReducer }}
      >
        {props.children}
      </ThemeSwitchContext.Provider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
