import React, { createContext, useReducer, useContext } from "react";
import validationAccountReducer from "../reducers/validationAccounts/validationAccountReducer";

export const ValidationAccountContext = createContext({});

const initialState = {
  validationAccountPage: 1,
  validationAccountSearchTerm: ""
};

export default function ValidationAccountProvider({ children }) {
  const validationAccountData = useReducer(validationAccountReducer, initialState);

  return (
    <ValidationAccountContext.Provider value={{ validationAccountData }}>
      {children}
    </ValidationAccountContext.Provider>
  );
}

export const useValidationAccountData = () => {
  const contextValue = useContext(ValidationAccountContext);
  return contextValue;
};