import React, { createContext, useReducer, useContext } from "react";
import scheduledReportsReducer from "../reducers/scheduledReports/scheduledReportsReducer";

export const ScheduledReportsContext = createContext({});

const initialState = {
  selectedScheduledReports: "",
};

export default function ScheduledReportsProvider({ children }) {
  const scheduledReportsData = useReducer(scheduledReportsReducer, initialState);

  return (
    <ScheduledReportsContext.Provider value={{ scheduledReportsData }}>
      {children}
    </ScheduledReportsContext.Provider>
  );
}

export const useScheduledReportsData = () => {
  const contextValue = useContext(ScheduledReportsContext);
  return contextValue;
};