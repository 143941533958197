import React, { useContext } from "react";

/* State */
import { useDispatch } from "react-redux";
import { reset as resetEntityState } from "../../state/slices/entities";
import { reset as resetUserState } from "../../state/slices/user";
import { reset as resetCallCenterState } from "../../state/slices/callcenter";

/* Hooks */
import useAuthContext from "../../hooks/useAuthContext";

/* Constants */
import * as c from "../../constants";

const AppActionContext = React.createContext();

const AppActionProvider = ({ children }) => {
  const { logOut: authLogout } = useAuthContext();
  const dispatch = useDispatch();

  const logOut = () => {
    authLogout();
    window.sessionStorage.setItem(c.DEFAULT_PROPERTY_ID, undefined);
    window.sessionStorage.removeItem(c.DEFAULT_SCOPE_AWARE_IDS);
    dispatch(resetCallCenterState());
    dispatch(resetUserState());
    dispatch(resetEntityState());
  };

  return (
    <AppActionContext.Provider value={{ logOut }}>
      {children}
    </AppActionContext.Provider>
  );
};

export const useAppActionContext = () => useContext(AppActionContext);

export default AppActionProvider;
