import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0)
    } 
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  }
}));
