import React, { createContext, useMemo, useReducer } from "react";
import apiClient from "../auth/apiClient";
import TaxRatesService from "../services/TaxRatesService";
import taxRateReducer from "../reducers/taxes/taxRateReducer";

export const TaxRatesContext = createContext();

const initialState = {};

const taxRatesService = new TaxRatesService(apiClient);

async function getPageableTaxRates(entityID, limit, offset, searchTerm) {
  return await taxRatesService.getPageableTaxRates(
    entityID,
    limit,
    offset,
    searchTerm
  );
}

async function getTaxRate(taxRateID) {
  return await taxRatesService.getTaxRate(taxRateID);
}

async function createTaxRate(facilityID, taxRateInfo) {
  return taxRatesService.createTaxRate(facilityID ?? "", taxRateInfo);
}

async function updateTaxRate(facilityID, taxRateInfo) {
  return taxRatesService.updateTaxRate(facilityID ?? "", taxRateInfo);
}

async function deleteTaxRates(facilityID, taxRateID) {
  return taxRatesService.deleteTaxRates(facilityID ?? "", taxRateID);
}

const TaxRatesProvider = (props) => {
  const value = useMemo(() => {
    return {
      createTaxRate,
      updateTaxRate,
      deleteTaxRates,
      getPageableTaxRates,
      getTaxRate,
    };
  }, []);

  return (
    <TaxRatesContext.Provider value={{ ...value, taxRateReducer: useReducer(taxRateReducer, initialState) }}>
      {props.children}
    </TaxRatesContext.Provider>
  );
};

export default TaxRatesProvider;
