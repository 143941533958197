import React, { createContext, useMemo, useReducer } from "react";
import apiClient from "../auth/apiClient";
import TaxesService from "../services/TaxesService";
import taxReducer from "../reducers/taxes/taxReducer";

export const TaxesContext = createContext();

const initialState = {};

const taxesService = new TaxesService(apiClient);

async function getPageableTaxes(entityID, limit, offset, searchTerm) {
  return await taxesService.getPageableTaxes(
    entityID,
    limit,
    offset,
    searchTerm
  );
}

async function getTax(taxID) {
  return await taxesService.getTax(taxID);
}

async function createTax(facilityID, taxInfo) {
  return taxesService.createTax(facilityID ?? "", taxInfo);
}

async function updateTax(facilityID, taxInfo) {
  return taxesService.updateTax(facilityID ?? "", taxInfo);
}

async function deleteTaxes(facilityID, taxID) {
  return taxesService.deleteTaxes(facilityID ?? "", taxID);
}

const TaxesProvider = (props) => {
  const value = useMemo(() => {
    return {
      createTax,
      updateTax,
      deleteTaxes,
      getPageableTaxes,
      getTax,
    };
  }, []);

  return (
    <TaxesContext.Provider value={{ ...value, taxReducer: useReducer(taxReducer, initialState) }}>
      {props.children}
    </TaxesContext.Provider>
  );
};

export default TaxesProvider;
