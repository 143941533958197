import React, { createContext, useReducer, useContext } from "react";
import accessGroupReducer from "../reducers/accessGroups/accessGroupReducer";

export const AccessGroupContext = createContext({});

const initialState = {
  accessGroupPage: 1,
  searchTerm: ""
};

export default function AccessGroupProvider({ children }) {
  const accessGroupData = useReducer(accessGroupReducer, initialState);

  return (
    <AccessGroupContext.Provider value={{ accessGroupData }}>
      {children}
    </AccessGroupContext.Provider>
  );
}

export const useAccessGroupData = () => {
  const contextValue = useContext(AccessGroupContext);
  return contextValue;
};