import React, { createContext, useEffect, useReducer } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import AuthService from "../services/AuthService";
import AuthReducer, {
  initialState,
  setAuthenticationState,
  setCurrentProperty,
  AuthStatus,
} from "../reducers/auth/authReducer";
import apiClient, {
  setAuthentication as setApiAuth,
  removeAuthentication as removeApiAuth,
  removeEntities as removeApiClientEntities,
  setEntities as setApiClientEntities,
} from "../auth/apiClient";
import { DEFAULT_PROPERTY_ID } from "../constants";
import useTimeout from "../hooks/useTimeout";
import useAuthAudit from "../hooks/auditing/useAuthAudit";
import UserService from "../services/UserService";
import { isGuid } from "../App";

export const AuthContext = createContext();

const authService = new AuthService(apiClient);
const userService = new UserService(apiClient);

const AuthProvider = (props) => {
  const authReducer = useReducer(AuthReducer, initialState);
  const [state, dispatch] = authReducer;

  const refreshUserSession = () => {
    authService
      .refreshUserSession()
      .then((result) => {
        setUserSession(result);
      })
      .catch(async (error) => {
        console.error(error);
        setUserSession(null);
      });
  };

  const { setExpirationDt } = useTimeout({
    onTimeout: refreshUserSession,
  });

  const { markAsUserHasLoggedIn, auditLogout } = useAuthAudit({
    authData: state,
  });

  useEffect(() => {
    if (!state.userSession) {
      return;
    }

    var exp = state.userSession.getIdToken().getExpiration();
    if (exp) {
      // Refresh tokens 10 seconds before they expire
      var expirationDt = new Date(0);
      expirationDt.setUTCSeconds(exp - 10);
      setExpirationDt(expirationDt);
    }
  }, [state]);

  const getPermissions = async (userID, facilityID = undefined) => {
    let permissions = [];

    const result = await authService.getPermissions(userID, facilityID);
    if (result.data) {
      permissions = result.data;
    }

    return permissions;
  };

  const checkIfAdmin = async (userID) => {
    const result = await userService.isUserAdmin(userID);
    if (result.status === 200) return result.data;
    else return false;
  };

  const extractPropertyInfoFromPermissions = (permissions) => {
    return permissions.map((grouping) => {
      return {
        facilityID: grouping.entityID,
        name: grouping.name,
        parentID: grouping.parentEntityID,
        parentName: grouping.parentName,
      };
    });
  };

  const formatScopedPermissions = (permissions) => {
    if (!permissions) {
      return {};
    }

    let scopedPermissions = {};
    permissions.forEach((group) => {
      scopedPermissions[group.entityID] = [
        ...(group.permissions?.map((permission) => {
          return permission.permissionName;
        }) ?? []),
      ];
    });
    return scopedPermissions;
  };

  const setUserSession = React.useCallback(
    async (session) => {
      if (session) {
        setApiAuth(session.idToken.jwtToken);
        const sessionPropertyID = window.sessionStorage.getItem(
          DEFAULT_PROPERTY_ID
        );

        let permissions = [];
        let isAdmin = false;
        try {
          const userID = session.idToken?.payload?.userid;
          isAdmin = await checkIfAdmin(userID);
          // check if we first have a facilityID in session
          if (isGuid(sessionPropertyID)) {
            // get permissions at the given scope
            permissions = await getPermissions(userID, sessionPropertyID);
            permissions = permissions.map(({ entityID, permissions }) => {
              return {
                entityID,
                permissions,
              };
            });
          } else {
            // fetch permissions for user which should just retrieve the first scope by default
            permissions = [await getPermissions(userID)];
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: setAuthenticationState,
            payload: {
              permissions,
              authStatus: AuthStatus.AUTHENTICATED,
              userSession: session,
              properties: [],
              currentToken: session.idToken.jwtToken,
              isAdmin: false,
            },
          });

          return;
        }

        const scopedPermissions = formatScopedPermissions(permissions);
        const properties = extractPropertyInfoFromPermissions(permissions);
        const currentFacility =
          properties.find((x) => x.facilityID === sessionPropertyID) ??
          properties[0];

        if (sessionPropertyID !== currentFacility?.facilityID) {
          window.sessionStorage.setItem(
            DEFAULT_PROPERTY_ID,
            currentFacility?.facilityID ?? ""
          );
        }
        setApiClientEntities([currentFacility?.facilityID ?? ""]);
        dispatch({
          type: setAuthenticationState,
          payload: {
            permissions: scopedPermissions,
            authStatus: AuthStatus.AUTHENTICATED,
            userSession: session,
            properties,
            currentFacility,
            currentToken: session.idToken.jwtToken,
            isAdmin: isAdmin,
          },
        });
      } else {
        removeApiAuth();
        dispatch({
          type: setAuthenticationState,
          payload: {
            permissions: {},
            authStatus: AuthStatus.UNAUTHENTICATED,
            userSession: null,
            properties: [],
            currentToken: "",
            isAdmin: false,
          },
        });
      }
    },
    [dispatch]
  );

  const logOut = () => {
    auditLogout().catch((error) => {
      console.error("Error during logout audit", error);
    });
    authService.handleLogout();
    setUserSession(null);
  };

  const setCurrentFacility = (facility) => {
    dispatch({
      type: setCurrentProperty,
      payload: facility,
    });
  };

  useEffect(() => {
    authService
      .retrieveUser()
      .then((result) => {
        setUserSession(result);
      })
      .catch(async (error) => {
        setUserSession(null);
      });

    return () => setUserSession(null);
  }, [setUserSession]);

  useEffect(() => {
    if (state.currentFacility) {
      setApiClientEntities([state.currentFacility.facilityID]);
    } else {
      removeApiClientEntities();
    }
  }, [state.currentFacility]);

  return (
    <AuthContext.Provider
      value={{
        authService,
        logOut,
        setUserSession,
        authReducer,
        setCurrentFacility,
        refreshUserSession,
        markAsUserHasLoggedIn,
      }}
    >
      {state.authStatus === AuthStatus.PENDING ? (
        <Box
          height="75vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="3rem" />
          <Typography variant="h5">Loading...</Typography>
        </Box>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
