import React, { createContext, useReducer, useContext } from "react";
import routineReducer from "../reducers/routines/routineReducer";

export const RoutineContext = createContext({});

const initialState = {
  routinePage: 1,
  searchTerm: ""
};

export default function RoutineProvider({ children }) {
  const routineData = useReducer(routineReducer, initialState);

  return (
    <RoutineContext.Provider value={{ routineData }}>
      {children}
    </RoutineContext.Provider>
  );
}

export const useRoutineData = () => {
  const contextValue = useContext(RoutineContext);
  return contextValue;
};