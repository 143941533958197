export const onTaxRateFilterChange = "TAXRATE_FILTER_CHANGE";

export default function taxRateReducer(state, { type, payload }) {
  switch (type) {
    case onTaxRateFilterChange:
      return { ...state, ...payload };
    default:
      return state;
  }
}
