import React, { createContext, useContext, useMemo } from "react";
import GroupService from "../services/GroupService";
import apiClient from "../auth/apiClient";
import UserService from "../services/UserService";

export const GroupContext = createContext();

const userService = new UserService(apiClient);
const groupService = new GroupService(apiClient);

const getGroups = async (groupType, entityID, limit, offset, searchTerm) => {
  switch (groupType?.toLowerCase()) {
    case "user":
      return await userService.getUserGroups(
        entityID,
        limit,
        offset,
        searchTerm
      );
    default:
      return await groupService.getGroups(
        groupType,
        entityID,
        limit,
        offset,
        searchTerm
      );
  }
};

const GroupProvider = ({ children }) => {
  const functions = useMemo(() => {
    return { getGroups };
  });

  return (
    <GroupContext.Provider value={{ functions }}>
      {children}
    </GroupContext.Provider>
  );
};

export const useGroupContext = () => {
  return useContext(GroupContext);
};

export default GroupProvider;
