import React, { useEffect } from "react";
import TaxRatesProvider from "../../providers/TaxRatesProvider";
import TaxesManagementContainer from "../../components/Taxes/Taxes/TaxesManagementContainer";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import TreeView from "../../components/TreeView";
import { Route, Switch } from "react-router-dom";
import { useStyles } from "./styles";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import GroupIcon from "@material-ui/icons/Group";
import TaxRatesManagementContainer from "../../components/Taxes/TaxRates/TaxRatesManagementContainer";
import TaxesProvider from "../../providers/TaxesProvider";

const TaxesManagementPage = (props) => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  const treeItems = [
    {
      name: "Tax Rates",
      icon: PeopleAltIcon,
      link: `/taxmgmt/tax-rates`,
    },
    {
      name: "Taxes",
      icon: GroupIcon,
      link: `/taxmgmt/taxes`,
    },
  ];

  return (
    <TaxesProvider>
      <TaxRatesProvider>
        <Grid container className={classes.root}>
          <Grid item xs={3} lg={2}>
            <TreeView treeItems={treeItems} />
          </Grid>
          <Grid className={classes.contentContainer} item xs={9} lg={10}>
            <Switch>
              <Route
                path="/taxmgmt/tax-rates"
                component={TaxRatesManagementContainer}
              />
              <Route
                path="/taxmgmt/taxes"
                component={TaxesManagementContainer}
              />
            </Switch>
          </Grid>
        </Grid>
      </TaxRatesProvider>
    </TaxesProvider>
  );
};
export default TaxesManagementPage;
