import { SORT_DIRECTION } from "../../components/Filters/GroupContractFilters";

export const setSelectedContract = "SET_SELECTED_CONTRACT";
export const onFiltersChanged = "ON_FILTERS_CHANGED";
export const clearContractFilters = "CLEAR_CONTRACT_FILTERS";

export default function contractReducer(state, { payload, type }) {
  switch (type) {
    case setSelectedContract:
      return { ...state, selectedContract: payload };
    case onFiltersChanged:
      return { ...state, ...payload };
    case clearContractFilters:
        return {
          ...state,
          credentialType: "",
          status: "",
          mode: "",
          currentContractAccessHoldersPage: 1,
          availableContractAccessHoldersPage: 1,
          searchTerm: "",
          group: null,
          nameSortDirection: SORT_DIRECTION.NONE,
          acctnumberSortDirection: SORT_DIRECTION.NONE,
        };      
    default:
      return state;
  }
}
