import React, { createContext } from "react";
import Moment from "moment";

export const FormStateContext = createContext();

const FormStateProvider = props => {
  const [isEditingStarted, setIsEditingStarted] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({
    rate: "",
    title: "",
    date: "",
    isPast: ""
  });

  const addError = (type, text) => {
    const updated = { ...formErrors, [type]: text };
    setFormErrors(updated);
  };
  const removeError = type => {
    const updated = { ...formErrors, [type]: "" };
    setFormErrors(updated);
  };

  const isEventStarted = start => {
    if (Moment(start).add("minutes", 1) < Moment()) {
      setFormErrors({
        ...formErrors,
        isPast:
          "You cannot create or update events that have already begun or are past"
      });
    } else if (formErrors.isPast !== "") {
      setFormErrors({ ...formErrors, isPast: "" });
    }
  };

  const isDateInPast = start => {
    if (Moment(start).add("days", 1) < Moment()) {
      setFormErrors({
        ...formErrors,
        isPast:
          "You cannot create or update events that have already begun or are past"
      });
    } else if (formErrors.isPast !== "") {
      setFormErrors({ ...formErrors, isPast: "" });
    }
  };
  const clearErrors = () => {
    setFormErrors({ rate: "", title: "", date: "", isPast: "" });
    setIsEditingStarted(false);
  };
  return (
    <FormStateContext.Provider
      value={{
        addError,
        removeError,
        clearErrors,
        isEventStarted,
        isEditingStarted,
        setIsEditingStarted,
        isDateInPast,
        formErrors
      }}
    >
      {props.children}
    </FormStateContext.Provider>
  );
};

export default FormStateProvider;
