export const onTaxFilterChange = "TAX_FILTER_CHANGE";

export default function taxReducer(state, { type, payload }) {
  switch (type) {
    case onTaxFilterChange:
      return { ...state, ...payload };
    default:
      return state;
  }
}
