import React, { useEffect, useCallback, useState } from "react";
import DownloadValidationsForm from "../../components/Forms/Validation/Download";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import { useSelector } from "react-redux";
import { useStyles } from "./style";
import ValidationAccountService from "../../services/ValidationAccountService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import Loading from "../../components/Loading";
import "animate.css/animate.css";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import useHasPermissions from "../../hooks/useHasPermissions";
import { Grid, Box } from "@material-ui/core";
import ApplyValidationsPanel from "../../components/Panels/Validations/ApplyValidations";
import AutomaticValidationService from "../../services/AutomaticValidationService";

const validationAccountService = new ValidationAccountService(apiClient);
const automaticValidationService = new AutomaticValidationService(apiClient);

const ValidatePage = props => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const currentFacility = useSelector(state => state.entities?.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || currentFacility);
  const currentUserID = useSelector(state => state.user?.UserID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [validationAccounts, setValidationAccounts] = useState();
  const [autoApplyValidationOffer, setAutoApplyValidationOffer] = useState();
  const [loading, setLoading] = useState(true);
  const { hasPermissions } = useHasPermissions();
  const PrintValidationPermission = hasPermissions([
    "createprintedvalidations"
  ]);
  const validateTicketsPermission = hasPermissions(["validatetickets"]);
  const [automaticallyApplyValidationEnabled, setAutomaticallyApplyValidationEnabled] = useState(false);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  useEffect(() => {
    if (currentUserID && scopeAwareFacilityID) {
      getValidationAccountsForUser();
      if (isGuidNotAllZeros(currentUserID)) {
        getAutomaticValidation();
      }
    }
  }, [currentUserID, scopeAwareFacilityID]);

  function isGuidNotAllZeros(guid) {
    const pattern = /^[0]{8}-[0]{4}-[0]{4}-[0]{4}-[0]{12}$/;
    return !pattern.test(guid);
  }

  const getValidationAccountsForUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await validationAccountService.getValidationAccountsForUser(
        scopeAwareFacilityID,
        currentUserID,
        true
      );
      setValidationAccounts(response.data.map(x => x.contractHolderID));
    } catch (err) {
      enqueueSnackbar("Failed to retrieve validation account info", {
        variant: "error",
        tag: "FailedToRetrieveValidationAccountInfo"
      });
    } finally {
      setLoading(false);
    }
  }, [scopeAwareFacilityID, currentUserID]);

  const getAutomaticValidation = useCallback(async () => {
      try {
        const response = await automaticValidationService.getAutomaticValidation(currentUserID, scopeAwareFacilityID);

        // NOTE: As of now only one Validation Account in a Facility can have an auto offer
        // However, there are plans to support more than one Validation Account per Facility but will
        // require UI changes.
        setAutoApplyValidationOffer(response.data?.[0]?.validationOfferID);
        setAutomaticallyApplyValidationEnabled(response.data?.length >= 1);
      } catch (err) {
        if (err.response.status != 404) {
          enqueueSnackbar(`Failed to retrieve automatic validation.`, {
            variant: "error",
            tag: "FailedToRetrieveAutomaticValidation",
          });
        }
      }
    },
    [currentUserID, scopeAwareFacilityID]
  );

  return (
    <div className={classes.root}>
      {loading === true ? (
        <Loading className={classes.spinner} />
      ) : (
        <div
          className={clsx(
            "animate__animated",
            "animate__fadeIn",
            classes.formContainer
          )}
        >
          {!validationAccounts || validationAccounts?.length === 0 ? (
            <Typography
              className={classes.noValidationAccountMessage}
              data-testid="no-validation-account-message"
            >
              You have no validation accounts associated with your user
            </Typography>
          ) : (
            <Box>
              <Grid container spacing={1}>
                {PrintValidationPermission && (
                  <Grid item xs>
                    <DownloadValidationsForm
                      data-testid={"print-val-form"}
                      className={clsx([
                        "print-val-form",
                        classes.printValidationForm
                      ])}
                      entityID={scopeAwareFacilityID}
                      contractHolderIDs={validationAccounts}
                    />
                  </Grid>
                )}
                {validateTicketsPermission && (
                  <Grid item xs>
                    <ApplyValidationsPanel
                      data-testid={"apply-val-panel"}
                      className={clsx(
                        "apply-val-panel",
                        classes.applyValidationPanel
                      )}
                      facilityID={currentFacility} /* keep current facility as query will find the FG if necessary */
                      contractHolderIDs={validationAccounts}
                      scopeAwareFacilityID={scopeAwareFacilityID}
                      autoApplyValidationOffer={autoApplyValidationOffer}
                      automaticallyApplyValidationEnabled={automaticallyApplyValidationEnabled}
                    />
                  </Grid>
                )}
              </Grid>
              {/* This form is for phase1b */}
              {/* <EmailValidationForm
                className={classes.emailValidationForm}
                entityID={currentFacility}
                contractHolderID={validationAccount.contractHolderID}
              /> */}
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default ValidatePage;
