export const setSelectedValidationAccount = "SET_SELECTED_MERCHANT_ACCOUNT";
export const onFiltersChanged = "ON_FILTERS_CHANGED";

export default function validationAccountReducer(state, { payload, type }) {
  switch (type) {
    case setSelectedValidationAccount:
      return { ...state, selectedValidationAccount: payload };
    case onFiltersChanged:
      return { ...state, ...payload };
    default:
      return state;
  }
}