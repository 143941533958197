import React, { createContext, useReducer, useContext } from "react";
import payonentryReducer from "../reducers/payonentry/payonentryReducer";

export const PayOnEntryContext = createContext({});

const initialState = {
  payonentryPage: 1,
  searchTerm: ""
};

export default function PayOnEntryProvider({ children }) {
  const payonentryData = useReducer(payonentryReducer, initialState);

  return (
    <PayOnEntryContext.Provider value={{ payonentryData }}>
      {children}
    </PayOnEntryContext.Provider>
  );
}

export const usePayOnEntryData = () => {
  const contextValue = useContext(PayOnEntryContext);
  return contextValue;
};