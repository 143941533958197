export const onFilterChange = "FILTER_CHANGE";
export const addUser = "ADD_USER";
export const editUser = "EDIT_USER";
export const closeUserForm = "CLOSE_USER_FORM";
export const addGroup = "ADD_GROUP";
export const editGroup = "EDIT_GROUP";
export const closeGroupForm = "CLOSE_GROUP_FORM";

export default function userReducer(state, { type, payload }) {
  switch (type) {
    case onFilterChange:
      return { ...state, ...payload };
    case addUser:
      return { ...state, userID: undefined, userFormOpen: true };
    case editUser:
      return { ...state, userID: payload.userID, userFormOpen: true };
    case closeUserForm:
      return { ...state, userID: undefined, userFormOpen: false };
    case addGroup:
      return { ...state, userID: undefined, groupFormOpen: true };
    case editGroup:
      return { ...state, groupID: payload.groupID, groupFormOpen: true };
    case closeGroupForm:
      return { ...state, groupID: undefined, groupFormOpen: false };
    default:
      return state;
  }
}
