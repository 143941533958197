import React, { createContext, useMemo, useReducer, useContext } from "react";
import roamingCCTerminalPageReducer from "../reducers/cashier/roamingCCTerminalPageReducer";
import roamingCCTerminalEditReducer from "../reducers/cashier/roamingCCTerminalEditReducer";
import CashierService from "../services/CashierService";
import apiClient from "../auth/apiClient";

export const CashierContext = createContext({});

const initialPageState = {
  page : 1,
  searchTerm: ""
};

const initalRoamingCCTerminalState = { 
  roamingCCTerminalID: undefined, 
  showForm: false
};

const cashierService = new CashierService(apiClient);

const createRoamingCCTerminal = async (RoamingCCTerminal) => {
  return await cashierService.createRoamingCCTerminal(RoamingCCTerminal);
}

const deleteRoamingCCTerminal = async (RoamingCCTerminal) => {
  return await cashierService.deleteRoamingCCTerminal(RoamingCCTerminal);
}

const updateRoamingCCTerminal = async (RoamingCCTerminal) => {
  return await cashierService.updateRoamingCCTerminal(RoamingCCTerminal);
}

export default function CashierProvider({ children }) {
  const roamingCCTermianlPageData = useReducer(roamingCCTerminalPageReducer, initialPageState);
  const roamingCCTerminalEditData = useReducer(roamingCCTerminalEditReducer, initalRoamingCCTerminalState);
  const functions = useMemo(() => {
    return {
      createRoamingCCTerminal,
      deleteRoamingCCTerminal,
      updateRoamingCCTerminal,
    };
  }, []);  
  return (
    <CashierContext.Provider value={{ roamingCCTermianlPageData, roamingCCTerminalEditData, functions}}>
      {children}
    </CashierContext.Provider>
  );
}

export const useCashierContext = () => {
  const contextValue = useContext(CashierContext);
  return contextValue;
};