import React, { createContext, useMemo } from "react";
import apiClient from "../auth/apiClient";
import ApplyTaxesService from "../services/ApplyTaxesService";

export const ApplyTaxesContext = createContext();

const applyTaxesService = new ApplyTaxesService(apiClient);

async function getAppliedTaxesForEntity(entityID) {
  return await applyTaxesService.getAppliedTaxesForEntity(entityID);
}
async function applyTaxesToEntity(entityID, applyTaxes) {
  return applyTaxesService.applyTaxesToEntity(entityID ?? "", applyTaxes);
}

async function deleteAppliedTaxesForEntity(entityID) {
  return applyTaxesService.deleteAppliedTaxesForEntity(entityID ?? "");
}

const ApplyTaxesProvider = (props) => {
  const value = useMemo(() => {
    return {
      getAppliedTaxesForEntity,
      applyTaxesToEntity,
      deleteAppliedTaxesForEntity
    };
  }, []);

  return (
    <ApplyTaxesContext.Provider value={value}>
      {props.children}
    </ApplyTaxesContext.Provider>
  );
};

export default ApplyTaxesProvider;
