import React, { createContext, useReducer, useContext } from "react";
import contractReducer from "../reducers/contracts/contractReducer";

export const ContractManagementContext = createContext({});

const initialState = {
  selectedContract: "",
};

export const ContractManagementProvider = (props) => {
  return (
    <ContractManagementContext.Provider
      value={useReducer(contractReducer, initialState)}
    >
      {props.children}
    </ContractManagementContext.Provider>
  );
};

export const useContractManagementData = () => {
  const contextValue = useContext(ContractManagementContext);
  return contextValue;
};
