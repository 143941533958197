import React, { createContext } from "react";
import usePortalHubConnectionManager from "../hooks/usePortalHub/usePortalHubConnectionManager";

export const HubContext = createContext();

const HubProvider = ({ children }) => {
  const { isOpen, portalHub } = usePortalHubConnectionManager();

  return (
    <HubContext.Provider value={{ portalHub, Connected: isOpen }}>
      {children}
    </HubContext.Provider>
  );
};

export default HubProvider;
