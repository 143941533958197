import { EMPTY_GUID } from "../../constants";
export const setCurrentProperty = "SET_CURRENT_PROPERTY";
export const setProperties = "SET_PROPERTIES";
export const setUserSession = "SET_USER_SESSION";
export const setPermissions = "SET_PERMISSIONS";
export const setAuthenticationState = "SET_AUTHENTICATION_STATE";

export const AuthStatus = {
  PENDING: "PENDING",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const initialState = {
  authStatus: AuthStatus.PENDING,
  permissions: {},
  properties: [],
  currentFacility: { facilityID: EMPTY_GUID },
  currentToken: "",
};

export default function AuthReducer(state, { type, payload }) {
  switch (type) {
    case setCurrentProperty:
      return { ...state, currentFacility: payload };
    case setProperties:
      return { ...state, properties: payload };
    case setUserSession:
      return { ...state, userSession: payload };
    case setPermissions:
      return { ...state, permissions: payload };
    case setAuthenticationState:
      return {
        ...state,
        authStatus: payload.authStatus,
        permissions: payload.permissions,
        userSession: payload.userSession,
        properties: payload.properties,
        currentFacility:
          payload.currentFacility ?? initialState.currentFacility,
        currentToken: payload.currentToken ?? initialState.currentToken,
        isAdmin: payload.isAdmin
      };
    default:
      return state;
  }
}
