import React, { createContext, useMemo } from "react";
import apiClient from "../auth/apiClient";
import RateService from "../services/RateService";

export const RateContext = createContext();

const rateService = new RateService(apiClient);

// pass throughs to the service
const getRateAssignedToEntity = (entityId) => {
  return rateService.getRateAssignedToEntity(entityId);
};
const assignRateToEntity = (entityId, rateId) => {
  return rateService.assignRateToEntity(entityId, rateId);
};
const unassignRateFromEntity = (entityId) => {
  return rateService.unassignRateFromEntity(entityId);
};
const assignRateToEntityV2 = (entityId, details) => {
  return rateService.assignRateToEntityV2(entityId, details);
};
const getAvailableRates = (entityId, summary) => {
  return rateService.getAvailableRates(entityId, summary);
};
const createRate = (entityId, rate) => {
  return rateService.createRate(entityId, rate);
};
const updateRate = (entityID, rate) => {
  return rateService.updateRate(entityID, rate);
};
const deleteRate = (entityId, rateId) => {
  return rateService.deleteRate(entityId, rateId);
};
const apply = (entityId, entryTime, exitTime) => {
  return rateService.apply(entityId, entryTime, exitTime);
};
const GetAssignedRatesByRateID = (rateID) => {
  return rateService.GetAssignedRatesByRateID(rateID);
};

// todo: change this to use calculate direct (once calculate direct is implemented)
const applyRatesForPreview = (
  rateApps,
  startTime,
  durationRows,
  interval,
  entryColumns
) => {
  return rateService.applyRateForPreview(
    rateApps,
    startTime,
    durationRows,
    interval,
    entryColumns
  );
};

// todo: remove unused function
const createNewRateApplication = (pid, rate) => {
  return rateService.createNewRateApplication(pid, rate);
};

// todo: remove unused function
const getCurrentRates = (facilityID) => {
  return rateService.getCurrentRates(facilityID);
};

const RateProvider = ({ children }) => {
  const functions = useMemo(() => {
    return {
      getRateAssignedToEntity,
      assignRateToEntity,
      assignRateToEntityV2,
      unassignRateFromEntity,
      getAvailableRates,
      createRate,
      updateRate,
      deleteRate,
      apply,
      applyRatesForPreview,
      createNewRateApplication,
      getCurrentRates,
      GetAssignedRatesByRateID,
    };
  }, []);

  return (
    <RateContext.Provider value={{ functions }}>
      {children}
    </RateContext.Provider>
  );
};

export default RateProvider;
