import React, { useMemo } from "react";
import ReportService from "../services/ReportService";
import apiClient from "../auth/apiClient";

export const ReportContext = React.createContext();

const reportService = new ReportService(apiClient);

const getReports = async (facilityID) => {
  return await reportService.getReports(facilityID);
};

const ReportProvider = ({ children }) => {
  const functions = useMemo(() => {
    return {
      getReports,
    };
  }, []);

  return (
    <ReportContext.Provider value={{ functions }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportData = () => {
  return React.useContext(ReportContext);
};

export default ReportProvider;
