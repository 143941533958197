import React, { createContext, useMemo, useReducer } from "react";
import apiClient from "../auth/apiClient";
import userReducer from "../reducers/users/userReducer";
import UserService from "../services/UserService";

export const UserContext = createContext();

const initialState = {
  userFormOpen: false,
  userID: undefined,
  groupFormOpen: false,
  groupID: undefined
};

const userService = new UserService(apiClient);

async function getUsers(entityID, limit, offset, searchTerm, roles) {
  return await userService.getUsers(entityID, limit, offset, searchTerm, roles);
}

async function getUser(userId) {
  return await userService.getUser(userId);
}

async function getUserExists(email) {
  return await userService.getUserExists(email);
}

async function createUser(user) {
  return await userService.createUser(buildUserRequest(user));
}

async function updateUser(user) {
  return await userService.updateUser(buildUserRequest(user));
}

async function updateInfo(user) {
  return await userService.updateInfo(user);
}

async function deleteUser(entityID, userId) {
  return await userService.deleteUser(entityID, userId);
}

async function deleteUsers(entityID, userIds) {
  return await userService.deleteUsers(entityID, userIds);
}

async function getEntityRoles(entityID, limit, offset, searchTerm) {
  return await userService.getEntityRoles(entityID, limit, offset, searchTerm);
}

async function getAllPermissions() {
  return await userService.getAllPermissions();
}

async function getScopedPermissions(userID, entityID) {
  return await userService.getScopedPermissions(userID, entityID);
}

async function createUserGroup(group) {
  return await userService.createUserGroup(group);
}

async function updateUserGroup(group) {
  return await userService.updateUserGroup(group);
}

async function deleteUserGroups(groupIDs) {
  return await userService.deleteUserGroups(groupIDs);
}

async function getUserGroups(entityID, limit, offset, searchTerm) {
  return await userService.getUserGroups(entityID, limit, offset, searchTerm);
}

async function getUserByEmail(email) {
  return await userService.getUserByEmail(email);
}

async function getUserGroup(groupID) {
  return await userService.getUserGroup(groupID);
}

async function resendUserInvitation(user) {
  return await userService.resendUserInvitation(user);
}

async function assignUserGroups(user) {
  return await userService.assignUserGroups(buildUserRequest(user));
}

async function GetUsersWithTheirAdminGroupStatus(limit, offset) {
  return await userService.GetUsersWithTheirAdminGroupStatus(limit, offset);
}

async function DeleteUserFromAdminGroup(userID) {
  return await userService.DeleteUserFromAdminGroup(userID);
}

async function AssignUserToAdminGroup(userID) {
  return await userService.AssignUserToAdminGroup(userID);
}

const buildUserRequest = user => {
  let userRequest = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    entities: user.entities,
    userType: user.userType
  };
  if (user.userId !== "-1") userRequest.userId = user.userId;
  return userRequest;
};

const UserProvider = props => {
  const value = useMemo(() => {
    return {
      getUsers,
      getUser,
      getUserExists,
      getUserByEmail,
      createUser,
      updateUser,
      updateInfo,
      deleteUser,
      deleteUsers,
      getEntityRoles,
      getAllPermissions,
      getScopedPermissions,
      createUserGroup,
      updateUserGroup,
      deleteUserGroups,
      getUserGroup,
      getUserGroups,
      resendUserInvitation,
      assignUserGroups,
      GetUsersWithTheirAdminGroupStatus,
      DeleteUserFromAdminGroup,
      AssignUserToAdminGroup
    };
  }, []);

  return (
    <UserContext.Provider
      value={{ ...value, userReducer: useReducer(userReducer, initialState) }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
