import React, { useEffect } from "react";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import { Typography } from "@material-ui/core";
import CodeReader from "../../components/CodeReader";

const SupportPage = props => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  return <div>
    <Typography>Support Page!</Typography>
    <CodeReader />
  </div>;
};

export default SupportPage;
