import React, { createContext, useMemo, useContext } from "react";
import apiClient from "../auth/apiClient";
import CreditCardGatewayService from "../services/CreditCardGatewayService";

export const CCGatewayContext = createContext();
const GatewayService = new CreditCardGatewayService(apiClient);

const createCreditCardGatewaySetting = async (gatewaySetting) => {
  return await GatewayService.createCreditCardGatewaySetting(gatewaySetting);
};

const getCreditCardGateways = async (gatewayName, gatewayID) => {
  return await GatewayService.getCreditCardGateways(gatewayName, gatewayID);
};

const CreateCreditCardGatewayEntityMapping = async (gatewayEntityMapping) => {
  return await GatewayService.CreateCreditCardGatewayEntityMapping(
    gatewayEntityMapping
  );
};

const getCreditCardGatewaySettingsByEntityID = async (entityID) => {
  return await GatewayService.getCreditCardGatewaySettingsByEntityID(entityID);
};

const updateCreditCardGatewaySettings = async (gatewaySettings) => {
  return await GatewayService.updateCreditCardGatewaySettings(gatewaySettings);
};

const upsertSettings = async (entityID, gatewaySettings) => {
  return await GatewayService.upsertSettings(entityID, gatewaySettings);
};

export default function CCGatewayProvider({ children }) {
  const functions = useMemo(() => {
    return {
      createCreditCardGatewaySetting,
      getCreditCardGateways,
      CreateCreditCardGatewayEntityMapping,
      getCreditCardGatewaySettingsByEntityID,
      updateCreditCardGatewaySettings,
      upsertSettings,
    };
  }, []);

  return (
    <CCGatewayContext.Provider value={{ functions }}>
      {children}
    </CCGatewayContext.Provider>
  );
}

export const useGatewayContext = () => {
  const contextValue = useContext(CCGatewayContext);
  return contextValue;
};
