export const onFiltersChanged = "ON_FILTERS_CHANGED";

export default function roamiangCCTerminalPageReducer(state, { payload, type }) {
  switch (type) {
    case onFiltersChanged:
      return { ...state, ...payload };
    default:
      return state;
  }
}
