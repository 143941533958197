import React, { useEffect, useState } from "react";
import UserProvider from "../../providers/UserProvider";
import UserManagementContainer from "../../components/Users/UserManagementContainer";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import TreeView from "../../components/TreeView";
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  Redirect
} from "react-router-dom";
import { useStyles } from "./styles";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import GroupIcon from "@material-ui/icons/Group";
import UserGroupManagementContainer from "../../components/Users/Groups/UserGroupManagementContainer";
import useHasPermissions from "../../hooks/useHasPermissions";
import useCurrentFacility from "../../hooks/useCurrentFacility";

const UserManagementPage = props => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { facilityID } = useCurrentFacility();
  const location = useLocation();
  const history = useHistory();
  const [displayContent, setDisplayContent] = useState(false);
  const { hasPermissions } = useHasPermissions();
  const ViewUsersPermission = hasPermissions(["users.view"]);
  const ViewGroupsPermission = hasPermissions(["usergroups.view"]);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  useEffect(() => {
    // this will get more complex when we have more than two options they can get to
    // possible in the future do a recursive walk down the the tree hiearchy for options they can select, and just go to the first one they have access to view
    if (location.pathname === `/users` && !ViewUsersPermission) {
      history.push(`/users/groups`);
    }
  }, [location.pathname, facilityID]);

  const treeItems = [
    {
      name: "Users",
      icon: PeopleAltIcon,
      link: `/users`,
      permissions: ["users.view"]
    },
    {
      name: "Groups",
      icon: GroupIcon,
      link: `/users/groups`,
      permissions: ["usergroups.view"]
    }
  ];

  const handleItemSelected = item => {
    setDisplayContent(true);
  };

  return (
    <div className={classes.root}>
      <UserProvider>
        <Grid container className={classes.root}>
          <Grid item xs={3} lg={2}>
            <TreeView
              data-id="user-tree-view"
              treeItems={treeItems}
              onClick={handleItemSelected}
            />
          </Grid>
          <Grid className={classes.contentContainer} item xs={9} lg={10}>
            <Switch>
              {ViewGroupsPermission && (
                <Route
                  path="/users/groups"
                  component={UserGroupManagementContainer}
                />
              )}
              {ViewUsersPermission && (
                <Route path="/users" component={UserManagementContainer} />
              )}
              <Route render={() => <Redirect to={`/home`} />} />
            </Switch>
          </Grid>
        </Grid>
      </UserProvider>
    </div>
  );
};
export default UserManagementPage;
