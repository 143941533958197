export const onEmailSettingsChanged = "ON_EMAIL_SETTINGS_CHANGED";

export default function settingsReducer(state, { type, payload }) {
  switch (type) {
    case onEmailSettingsChanged:
      return { ...state, ...payload };
    default:
      return state;
  }
}
