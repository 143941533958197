import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  title: {
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  printValidationForm: {
    margin: theme.spacing(2),
  },
  applyValidationPanel: {
    margin: theme.spacing(2),
  },
  emailValidationForm: {
    margin: theme.spacing(2),
  },
  spinner: {},
  formContainer: {},
  noValidationAccountMessage: {
    textAlign: "center",
    fontStyle: "italic",
    marginTop: 50,
    width: "calc(100% - 15px)",
    animation: "fadeIn",
    animationDuration: "2s",
  },
  flexChild: { 
    margin: theme.spacing(1), 
    flexGrow: "1" 
  },
}));
