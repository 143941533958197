export const setRootContainerWidth = "SET_ROOT_CONTAINER_WIDTH";

export default function LayoutReducer(state, { type, payload }) {
  switch (type) {
    case setRootContainerWidth:
      return { ...state, rootWidth: payload };
    default:
      return state;
  }
}
