export const onAddRoamingCCT = "ADD_ROAMING_CCTERMINAL";
export const onEditRoamingCCT = "EDIT_ROAMING_CCTERMINAL";
export const onCloseRoamingCCT = "CLOSE_ROAMING_CCTERMINAL";

export default function roamiangCCTerminalEditReducer(state, { type, payload }) {
  switch (type) {
    case onAddRoamingCCT:
      return { ...state, roamingCCTerminalID: undefined, showForm: true };
    case onEditRoamingCCT:
      return { ...state, roamingCCTerminalID: payload.roamingCCTerminalID, showForm: true };
    case onCloseRoamingCCT:
      return { ...state, roamingCCTerminalID: undefined, showForm: false };
    default:
      return state;
  }
}
