import React, { createContext, useMemo, useContext } from "react";
import apiClient from "../auth/apiClient";
import FacilityService from "../services/FacilityService";
import AreaService from "../services/AreaService";
import LaneService from "../services/LaneService";
import DeviceService from "../services/DeviceService";
import EntityService from "../services/EntityService";

const EntityContext = createContext();

const getEntityService = (entitytype = "entity") => {
  switch(entitytype.toLowerCase()) {
    case "facility": 
      return new FacilityService(apiClient);

    case "area":
      return new AreaService(apiClient);

    case "lane":
      return new LaneService(apiClient);

    case "device":
      return new DeviceService(apiClient);
    
    case "entity":
      return new EntityService(apiClient);

    default:
      console.log("Did not recognize entity type");
      return null;
  }
};

const createEntity = async (facilityID, entity) => {
  const entityService = getEntityService(entity.entitytype);
  return await entityService?.createEntity(facilityID, entity);
};

const onboardFacility = async (facilityID, facility) => {
  const entityService = getEntityService("facility");
  return await entityService?.onboardFacility(facilityID);
}

const getEntities = async (entitytype, facilityID, offset, limit, searchTerm) => {
  const entityService = getEntityService(entitytype);
  return await entityService?.getEntities(
    facilityID,
    offset,
    limit,
    searchTerm
  );
};

const getEntity = async (entitytype, facilityID, entityID) => {
  const entityService = getEntityService(entitytype);
  return await entityService?.getEntity(facilityID, entityID);
};
const getRichEntity = async (entityID) => {
  const entityService = new EntityService(apiClient);
  return await entityService?.getRichEntity(entityID);
};
const updateEntity = async (entity) => {
  const entityService = getEntityService(entity.entitytype);
  return await entityService?.updateEntity(entity);
};

const deleteEntities = async (entitytype, [entityID]) => {
  const entityService = getEntityService(entitytype);
  return await entityService?.deleteEntities([entityID]);
};

const getAllowableChildren = async (entityID) => {
  const entityService = new EntityService(apiClient);
  return await entityService?.getAllowableChildren(entityID);
};

const getEntityChildren = async (parentEntityID, entityTypes) => {
  const entityService = getEntityService();
  return await entityService?.getEntityChildren(parentEntityID, entityTypes);
}

export default function EntityProvider({ children }) {
  const functions = useMemo(() => {
    return {
      createEntity,
      getEntities,
      getEntity,
      getRichEntity,
      updateEntity,
      deleteEntities,
      getAllowableChildren,
      getEntityChildren,
    };
  }, []);

  return (
    <EntityContext.Provider value={{ functions }}>
      {children}
    </EntityContext.Provider>
  );
}

export const useEntityData = () => {
  const contextValue = useContext(EntityContext);
  return contextValue;
};
