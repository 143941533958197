import React, { createContext, useReducer } from "react";
import apiClient from "../auth/apiClient";
import SettingsService from "../services/SettingsService";
import settingsReducer from "../reducers/settings/settingsReducer";

export const SettingsContext = createContext();

const settingsService = new SettingsService(apiClient);

const SettingsProvider = (props) => {

  const getSettings = (facilityID, includeType = false) => {
    return settingsService.getSettings(facilityID ?? "", includeType);
  };

  const getSettingByName = (facilityID, settingName) => {
    return settingsService.getSettingByName(facilityID ?? "", settingName);
  };

  const updateSetting = (facilityID, setting) => {
    return settingsService.updateSetting(facilityID ?? "", setting);
  };

  const deleteSettingByName = (facilityID, name) => {
    return settingsService.deleteSettingByName(facilityID ?? "", name);
  };

  const updateSettingsBulk = (settings) => {
    return settingsService.updateSettingsBulk(settings);
  };

  const getChildrenSettings = (facilityID) => {
    return settingsService.getChildrenSettings(facilityID ?? "");
  };

  return (
    <SettingsContext.Provider
      value={{
        getSettings,
        getSettingByName,
        updateSetting,
        deleteSettingByName,
        updateSettingsBulk,
        getChildrenSettings,
        reducer: useReducer(settingsReducer),
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
